<template>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item style="color: #fff">店铺管理</el-breadcrumb-item>
        <el-breadcrumb-item>申请关店</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card style=" overflow: auto">
        <el-steps :active="active" finish-status="success"  v-if="(active!==4&&active!=5)" align-center>
            <el-step title="填写关店原因"></el-step>
            <el-step title="处理售前售后订单"></el-step>
            <el-step title="提交申请"></el-step>
          </el-steps>
          <div v-if="(active==1)" style="margin-top: 48px;">
          <div  style="    display: flex;justify-content:center;">
           <div style=" display: flex;  flex-direction: column; align-items: left;white-space: nowrap;">
            <div class="input" style=" display: flex;justify-content:left; align-items: center; ">
              <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户名：</h5>
            <el-input  v-model="userName"  placeholder="请输入户名"  style="   width: 200px;">
  
            </el-input>
          </div>
            <div class="input" style=" display: flex;justify-content:left; align-items: center; ">
              <h5>&nbsp;&nbsp;&nbsp;开户行：</h5>
            <el-input  v-model="bankOfAccount"  placeholder="请输入开户行"  style="   width: 200px;">
  
            </el-input>
          </div>
            <div class="input" style=" display: flex; align-items: center; ">
              <h5>银行卡号：</h5>
            <el-input  v-model="bankCardNumber"  placeholder="请输入银行卡号"  style="   width: 400px;">
  
            </el-input>
          </div>
        <div class="input">
            <h5>关店原因：</h5>
          <el-input type="textarea" v-model="applyRemark"  placeholder="请输入内容" :rows="10" style="   width: 400px;">

          </el-input>
        </div>
        <p style="   color: #999;font-size: 14px;margin-top:20px ;">联系客服 : 4001583050</p>
        <div>
            <el-button   size="small" style="margin-top: 12px;" @click="putrefundApply"  type="primary">下一步</el-button>
        </div>
        </div> 
  
    </div> 
    <el-divider></el-divider>   
    <div class="explain">
        <h4>说明</h4>
        <h5>处理售前售后订单</h5>
        <p>提交关店申请之前请处理完所有订单。如还有售前及售后订单则无法申请关店。</p>
      </div>
    </div>
          <div  v-else-if="(active==2)">
            <h4>待处理订单</h4>
        
          <el-table
          :data="characte"
          style="width: 100%"
          ref="singleTable"
          highlight-current-row
          border
          row-key="id"
          stripe

        >
          <el-table-column prop="orderNo" label="订单编号" width="300" show-overflow-tooltip/>
          <el-table-column prop="userName" label="用户名" width="200" show-overflow-tooltip />
          <el-table-column prop="createTime" label="订单时间" width="200" show-overflow-tooltip />
           <el-table-column prop="userMobile" label="用户电话" width="200" show-overflow-tooltip />
           <el-table-column prop="totalMoney" label="订单总价" width="200" show-overflow-tooltip />
           <el-table-column prop="payMoney" label="实际支付" width="200" show-overflow-tooltip />
          <el-table-column prop="roleDescribe" label="状态" width="250" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.orderStatus==0">待付款</span>
             <span v-else-if="scope.row.orderStatus==1">待接单</span>
             <span v-else-if="scope.row.orderStatus==2">待发货</span>
             <span v-else-if="scope.row.orderStatus==3">待收货</span>
             <span v-else-if="scope.row.orderStatus==4">待使用</span>
             <span v-else-if="scope.row.orderStatus==5">已完成</span>
             <span v-else-if="scope.row.orderStatus==6">已取消</span>
             <span v-else-if="scope.row.orderStatus==7">已失效</span>
             <span v-else-if="scope.row.orderStatus==8">退款中</span>
             <span v-else-if="scope.row.orderStatus==9">退款成功</span>
             <span v-else-if="scope.row.orderStatus==10">退款失败</span>
      
              </template>
              </el-table-column>

        </el-table>
        <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, ]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div style=" width: 100%; display: flex;justify-content: center;  padding: 0 0 10px 0;">
        <el-button  style="margin-top: 12px;" @click="pusubmitRefundApply"  type="primary">提交申请</el-button>
        <el-button style="margin-top: 12px;" @click="pucancelRefundApply">取消申请</el-button>   
    </div>
</div>  
<div  v-else-if="(active==3)" style="margin-top:24px;">
    <div style="  display: flex; justify-content: center;  "  >
          <div style=" display: flex; flex-direction: column;align-items: center; ">
            <img  style="width: 54px;height: 54px;" src="../../assets/img/组 3525@2x.png" alt="">
              <h4  style="margin-bottom:12px; font-size: 24px;">操作成功</h4>
              <p style="font-size: 12px;color: #999;">预计七天内处理</p>
              <p style="font-size: 12px;color: #999; margin-top:10px;">
                申请账号：{{id}}
            </p>
            <p style="font-size: 12px;color: #999; margin-top:10px;">
                收款账户：支付原账户返款
            </p>
           </div>
      </div>
</div>
<div  v-else-if="(active==4)" style="margin-top:24px;">
      <div style="  display: flex; justify-content: center;  "  >
        <div style=" display: flex; flex-direction: column;align-items: center; ">
          <img  style="width: 54px;height: 54px;" src="../../assets/img/组 3528@2x.png" alt="">
            <h4  style="margin-bottom:12px; font-size: 24px;">申请失败</h4>
            <p style="font-size: 12px;color: #999;"> 请核对并修改以下信息后，再重新提交。</p>
            <div>
              <h4>您提交的内容有如下错误：</h4>
              <div style="display: flex; justify-content: left;align-items: center;"> <img  style="width: 12px;height: 12px;padding-right:10px ;" src="../../assets/img/组 3528@2x.png" alt=""><p style="font-size: 12px;color: #999;"> {{businessRefundRefusingRemark}}</p></div>
            </div>
         </div>
    </div>

    <div style="display: flex; justify-content: center;margin-top:20px;"> <el-button  size="small" style="margin-top: 12px;" @click="(active=1)" type="primary">再次申请</el-button>   </div>
</div>
<div  v-else-if="(active==5)" style="margin-top:24px;">
  <div style="  display: flex; justify-content: center;  "  >
        <div style=" display: flex; flex-direction: column;align-items: center; ">
          <img  style="width: 54px;height: 54px;" src="../../assets/img/组 3525@2x.png" alt="">
            <h4  style="margin-bottom:12px; font-size: 24px;">申请已通过</h4>
            <p style="font-size: 12px;color: #999;">如有其他问题请联系客服 : 4001583050</p>
         </div>
    </div>
</div>
      </el-card>
      </div>
      </template>
   

<script>
import time from "@/utils/time";
import {getListByUnfinishedOrder,getUnderway,cancelRefundApply, submitRefundApply,searchBusinessInfoByUserId,refundApply } from "../../api/index";
  export default {
    data() {
      return {
        bankCardNumber:"",
        bankOfAccount:"",
        userName:"",
        ids:"",
        applyRemark:'',
        active: '',
        characte:[],
        queryInfo:{
          size:10,
          page:1
        },
        id:'',
        businessRefundRefusingRemark:"",
        total:0,
      };
    },
created(){
    this.searchBusinessInfoByUser(window.sessionStorage.getItem('userId'))
},
    methods: {
      // 分页
 handleCurrentChange(data){
this.queryInfo.page=data
this.getListByUnfinished()
    },
      // 查询未完成订单
      async getListByUnfinished(){
       this.queryInfo.businessId=this.id
        const { data: res } = await  getListByUnfinishedOrder(this.queryInfo); 
      if (res.code !== 200) return this.$message.error(res.msg);
      for (let index = 0; index <res.body.list.length; index++) {
        res.body.list[index].createTime= time(res.body.list[index].createTime)
}
      this.characte=res.body.list
      this.total=res.body.total
      },
        // 点击取消退店
        async pucancelRefundApply(){
            const formData = new FormData();
      formData.append("id", this.ids);
      const { data: res } = await cancelRefundApply(formData); 
      
if (res.code !== 200) return this.$message.error(res.msg);
this.getsearchBusinessInfoById(this.id)
this.$message.success('取消成功')
        },
        async pusubmitRefundApply(){
      const formData = new FormData();
      formData.append("id", this.ids);
      const { data: res } = await submitRefundApply(formData); 
      
if (res.code !== 200) return this.$message.error(res.msg);
this.getsearchBusinessInfoById(this.id)
this.queryInfo.page=1
this.$message.success('提交成功')
        },
// 提交退店申请
    async putrefundApply(){
      if(!this.userName)return  this.$message.error("未输入户名")
      if(!this.bankOfAccount)return  this.$message.error("未输入开户行")
      if(!this.bankCardNumber)return  this.$message.error("未输入银行卡号")

  
      const formData = new FormData();
    
      formData.append("applyRemark", this.applyRemark);
      formData.append("bankCardNumber", this.bankCardNumber);
      formData.append("bankOfAccount", this.bankOfAccount);
      formData.append("userName", this.userName);
      formData.append("businessId", this.id);
      const { data: res } = await refundApply(formData);
if (res.code !== 200) return this.$message.error(res.msg);
 this.bankCardNumber=''
this.bankOfAccount=''
this.userName=''
this.applyRemark=''
this.getsearchBusinessInfoById(this.id)
this.$message.success('提交成功')
},

        //获取店铺id
        async searchBusinessInfoByUser(ids){
     const { data: res } = await searchBusinessInfoByUserId({userId:ids}); 
     this.id=res.body.id
this.getsearchBusinessInfoById(this.id)
        },
        async  getsearchBusinessInfoById(ids){
    const { data: date } = await getUnderway({businessId:ids});
if(!date.body||date.body.businessRefundStatus==5){
    this.active=1
   
}else if(date.body.businessRefundStatus==1){
    this.active=2
    this.getListByUnfinished()
}else if(date.body.businessRefundStatus==2){
    this.active=3
}else if(date.body.businessRefundStatus==4){
  this.active=4
  this.businessRefundRefusingRemark=date.body.businessRefundRefusingRemark
}else if(date.body.businessRefundStatus==3){
  window.sessionStorage.setItem('userIdentity',0)
  this.active=5
}
this.ids=date.body.id
        }
    
    }
  }
</script>
<style scoped>

::v-deep .el-step__head.is-success {
  color: rgb(52, 158, 250);
  border-color: rgb(52, 158, 250);

}
::v-deep .el-step__title.is-success {
    font-weight: bold;
    color: rgb(52, 158, 250);
   
}
::v-deep .el-step__description.is-success {
    color: rgb(52, 158, 250);
}
.input{
    width: 100%;
    display: flex;
    justify-content: center;

}
.explain{
    color: #999;
    margin-left:30px ;
}
.explain h4{
    margin-bottom:0 ;
}
.explain h5{
    margin: 10px 0 ;
}
.explain p{
    font-size: 12px;
}
</style>
